<template>
  <div>

    <b-row>
      <b-col>
        <b-card class="header-marcar_consulta bg-gradient-primary ">
          <b-row align-v="center">
            <b-col>
              <h1 class="">
              <feather-icon icon="UserIcon" size="30" class="mr-50" />
  
              
              {{ id ? 'Editar' : 'Adicionar' }} Paciente
              
              
              
              
              </h1>
              <!-- <h4 class="mt-1">Selecione um paciente:</h4> -->
              
            </b-col>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="button-right ml-1 botao-white-outline"
              v-on:click="onSubmit()"
            >
              <feather-icon icon="SaveIcon" class="mr-50" />
              <span class="align-middle">Salvar Paciente</span>
            </b-button>

          </b-row>
          
        </b-card>
      </b-col>
    </b-row>

    
    <b-card>
      <h4 class="mb-2">Dados do Paciente</h4>
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form
          class="chat-app-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row align-v="start">
            <b-col md="6">
              <b-form-group label="Primeiro Nome">
                <validation-provider
                  #default="{ errors }"
                  name="Primeiro Nome"
                  vid="firstName"
                  rules="required"
                >
                  <b-form-input
                    v-model="form.physicalPerson.firstName"
                    placeholder="Digite aqui"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Último Nome">
                <validation-provider
                  #default="{ errors }"
                  name="Último Nome"
                  vid="lastName"
                  rules="required"
                >
                  <b-form-input
                    v-model="form.physicalPerson.lastName"
                    placeholder="Digite aqui"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Gênero">
                <validation-provider
                  #default="{ errors }"
                  name="Gênero"
                  vid="gender"
                  rules="required"
                >
                  <v-select
                    v-model="form.gender"
                    :options="genderOptions"
                    item-text="name"
                    item-value="id"
                    label="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label="Idade">
                <b-form-input
                  type="number"
                  v-model="form.age"
                  placeholder="00"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <!-- TODO - Colocar campo de calendário -->

            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Data de nascimento"
                vid="birthDate"
                rules="required"
              >
                <b-form-group label="Data de Nascimento">
                  <flat-pickr
                    v-model="form.birthDate"
                    class="form-control"
                    :config="configFlatPickr"
                    placeholder="DD-MM-AAAA"
                    :required="true"
                    @on-change="changeBirthDate"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <b-form-group label="Nacionalidade">
                <b-form-input
                  v-model="form.nationality"
                  placeholder="Digite aqui"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Estado Civil">
                <validation-provider
                  #default="{ errors }"
                  name="Estado Civil"
                  vid="marital"
                  rules="required"
                >
                  <v-select
                    v-model="form.maritalStatus"
                    :options="maritalStatus"
                    item-text="name"
                    item-value="id"
                    label="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="CPF"
                vid="cpf"
                rules="required|cpf"
              >
                <b-form-group label="CPF">
                  <b-form-input
                    v-model="form.physicalPerson.cpf"
                    placeholder="Digite aqui"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- TODO - Qual é o campo de cns? -->
            <b-col md="6">
              <b-form-group label="CNS">
                <b-form-input v-model="form.cns" placeholder="Digite aqui" />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <validation-provider
                #default="{ errors }"
                name="Endereço"
                vid="address"
                rules="required"
              >
                <b-form-group label="Endereço">
                  <b-form-input
                    v-model="form.physicalPerson.person.address"
                    placeholder="Rua, Avenida, número"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="CEP"
                vid="postalCode"
                rules="required"
              >
                <b-form-group>
                  <b-form-input
                    v-model="form.physicalPerson.person.postalCode"
                    placeholder="CEP"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="Bairro"
                vid="neighborhood"
                rules="required"
              >
                <b-form-group>
                  <b-form-input
                    v-model="form.physicalPerson.person.neighborhood"
                    placeholder="Bairro"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="Cidade"
                vid="city"
                rules="required"
              >
                <b-form-group>
                  <b-form-input
                    v-model="form.physicalPerson.person.city"
                    placeholder="Cidade"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Estado"
                vid="state"
                rules="required"
              >
                <b-form-group label="Estado">
                  <b-form-input
                    v-model="form.physicalPerson.person.state"
                    placeholder="Digite aqui"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- TODO - Fazer o get de escolaridades -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Escolaridade"
                vid="schooling"
                rules="required"
              >
                <b-form-group label="Escolaridade">
                  <v-select
                    :options="schoolingOptions"
                    item-text="name"
                    item-value="id"
                    v-model="form.schooling"
                    label="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <b-form-group label="Ocupação">
                <b-form-input
                  v-model="form.occupation"
                  placeholder="Digite aqui"
                />
              </b-form-group>
            </b-col>
            <!-- TODO - Criar sistema de adicionar novos Dependentes -->
            <b-col md="8">
              
              <b-form-group label="Dependentes">

                <b-form-checkbox
              v-model="form.visitedPsy"
              name="check-button"
              switch
              inline
              class="mb-1"
            >
              Possui Dependentes?
            </b-form-checkbox>
            </br>

                <!-- <b-form-input
                  v-model="form.dependents"
                  placeholder="Filho, Sexo, Idade"
                /> -->

                <label for="tags-basic">Preencha conforme o modelo</label>
                <b-form-tags
                  v-model="form.dependents"
                  input-id="tags-basic"
                  class="mb-2"
                  placeholder="Nome, gênero, idade"
                  add-button-text="Adicionar"
                />
    
              </b-form-group>
              
            </b-col>

            <b-col md="5">
              <b-form-group label="Telefone">
                <b-form-input
                  v-model="form.physicalPerson.person.phoneNumber"
                  placeholder="Fixo"
                />
              </b-form-group>
            </b-col>

            <b-col md="5">
              <b-form-group label="Celular">
                <b-form-input
                  v-model="form.physicalPerson.person.mobilePhone"
                  placeholder="Celular"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Email">
                <b-form-input
                  v-model="form.physicalPerson.person.email"
                  type="email"
                  placeholder="exemplo@exemplo.com"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card>
      <h4 class="mb-2">Particularidades do Paciente</h4>

      <b-form class="chat-app-form">
        <b-row align-v="start">
          <b-col md="3">
            <b-form-checkbox
              v-model="form.visitedPsy"
              name="check-button"
              switch
              inline
            >
              Visitou um Psicólogo antes?
            </b-form-checkbox>
          </b-col>
          <b-col md="3">
            <b-form-checkbox
              v-model="form.outpatient"
              name="check-button"
              switch
              inline
            >
              Ambulatório
            </b-form-checkbox>
          </b-col>
          <b-col md="3">
            <b-form-checkbox
              v-model="form.internment"
              name="check-button"
              switch
              inline
            >
              Internamento
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row align-v="end">
          <b-col md="6">
            <label for="textarea-default">Observações</label>
            <b-form-textarea
              id="textarea-default"
              v-model="form.note"
              placeholder="Informe as observações"
              rows="3"
            />
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-card>
      <h4 class="mb-2">Dados da Mãe</h4>
      <b-form class="chat-app-form">
        <b-row align-v="end">
          <b-col md="6">
            <b-form-group label="Primeiro Nome">
              <b-form-input
                v-model="form.parents[0].firstName"
                placeholder="Digite aqui"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Último Nome">
              <b-form-input
                v-model="form.parents[0].lastName"
                placeholder="Digite aqui"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row align-v="end">
          <b-col md="2">
            <b-form-group label="Idade">
              <b-form-input
                v-model="form.parents[0].age"
                type="number"
                placeholder="00"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Nacionalidade">
              <b-form-input
                v-model="form.parents[0].nationality"
                placeholder="Digite aqui"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row align-v="end">
          <b-col md="6">
            <b-form-group label="Escolaridade">
              <v-select
                v-model="form.parents[0].schooling"
                :options="schoolingOptions"
                item-text="name"
                item-value="id"
                label="name"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Ocupação">
              <b-form-input v-model="form.parents[0].occupation" placeholder="Digite aqui" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <h4 class="mb-2 mt-3">Dados do Pai</h4>
      <b-form class="chat-app-form" @submit.prevent="onSubmit">
        <b-row align-v="end">
          <b-col md="6">
            <b-form-group label="Primeiro Nome">
              <b-form-input
                v-model="form.parents[1].firstName"
                placeholder="Digite aqui"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Último Nome">
              <b-form-input
                v-model="form.parents[1].lastName"
                placeholder="Digite aqui"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row align-v="end">
          <b-col md="2">
            <b-form-group label="Idade">
              <b-form-input
                type="number"
                v-model="form.parents[1].age"
                placeholder="00"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Nacionalidade">
              <b-form-input
                v-model="form.parents[1].nationality"
                placeholder="Digite aqui"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row align-v="end">
          <b-col md="6">
            <b-form-group label="Escolaridade">
              <v-select
                v-model="form.parents[1].schooling"
                :options="schoolingOptions"
                label="name"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Ocupação">
              <b-form-input v-model="form.parents[1].occupation" placeholder="Digite aqui" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row align-v="end">
          <b-col md="6">
            <label for="textarea-default">Observações</label>
            <b-form-textarea
              v-model="form.parentNote"
              id="textarea-default"
              placeholder="Informe as observações"
              rows="3"
            />
          </b-col>
        </b-row>
      </b-form>

      <b-row align-h="end" class="m-1" >
        
      </b-row>
      
    </b-card>
   
  </div>
</template>

<script>
import schoolingService from '@/services/schoolingService'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, cpf } from '@validations'

import {
  BCard,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BCardText,
  BFormCheckbox,
  BFormTags,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import patientService from '@/services/patientService'
import maritalService from '@/services/maritalService'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import DependentesFormModal from '@/components/modals/DependentesFormModal.vue'
import { cloneObject } from '@/@core/utils/utils'
import { hideSpinner, showSpinner } from '@/services/spinService'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import * as moment from 'moment'

export default {
  components: {
    DependentesFormModal,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BCard,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
    BCardText,
    BFormTextarea,

    BFormCheckbox,
    flatPickr,
    BFormTags,
  },
  props: ['id'],
  data: () => ({
    configFlatPickr: { dateFormat: 'd/m/Y', locale: Portuguese },
    showDependentesModal: true,
    required,
    sexos: ['Masculino', 'Feminino', 'Outros'],
    schoolingOptions: [],
    maritalStatus: [],
    genderOptions: [
      { name: 'Masculino', id: 'M' },
      { name: 'Feminino', id: 'F' },
    ],
    meses: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    form: {
      age: 0,
      birthDate: undefined,
      createdBy: undefined,
      dependents: undefined,
      gender: undefined,
      internment: true,
      maritalStatus: undefined,
      nationality: undefined,
      note: undefined,
      occupation: undefined,
      outpatient: true,
      parentNote: undefined,
      parents: [{}, {}],
      physicalPerson: {
        cpf: undefined,
        createdBy: undefined,
        firstName: undefined,
        lastName: undefined,
        professionalBoardNumber: '123456',
        person: {
          address: undefined,
          city: undefined,
          createdBy: undefined,
          email: undefined,
          login: undefined,
          mobilePhone: undefined,
          neighborhood: undefined,
          phoneNumber: undefined,
          postalCode: undefined,
          state: undefined,
          status: undefined,
          updatedBy: undefined,
        },
        updatedBy: undefined,
      },
      schooling: undefined,
      updatedBy: undefined,
      visitedPsy: true,
    },
  }),
  methods: {
    changeBirthDate(event) {
      this.form.age = moment().diff(moment(new Date(event)), 'years', false)
    },
    prepareRequest(obj) {
      return {
        ...obj,
        gender: obj.gender.id,
        birthDate: `${obj.birthDate.split('/').reverse().join('-')} 00:00`,
      }
    },
    async onSubmit() {
      if (await this.$refs.refFormObserver.validate()) {
        // showSpinner()
        patientService[this.id ? 'update' : 'create'](
          this.prepareRequest(cloneObject(this.form))
        )
          .then(res => {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Dados salvos com sucesso!',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            if (!this.id) {
              console.log(res)
              this.$router.push({ name: 'pacientes' })
            }
          })
          .finally(() => {
            hideSpinner()
          })
      } else {
        this.$toast({
          component: ToastificationContentVue,
          position: 'top-right',
          props: {
            title: 'Verifique os campos!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async getSchoolings() {
      this.schoolingOptions = await schoolingService.getAll()
    },
    async getMaritals() {
      this.maritalStatus = await maritalService.getAll()
    },
    async getPatientById() {
      const patient = await patientService.getById(this.id)
      patient.gender = this.genderOptions.find(
        gender => gender.id === patient.gender
      )
      this.form = patient
    },
  },
  computed: {
    dias() {
      return Object.keys(new Array(31).fill(0)).map(i => parseInt(i) + 1)
    },
    anos() {
      return Object.keys(new Array(150).fill(0)).map(i => parseInt(i) + 1852)
    },
  },
  mounted() {
    this.getSchoolings()
    this.getMaritals()
    console.log(this.id)
    if (this.id) {
      this.getPatientById()
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
