var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', [_c('b-card', {
    staticClass: "header-marcar_consulta bg-gradient-primary "
  }, [_c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', [_c('h1', {}, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "UserIcon",
      "size": "30"
    }
  }), _vm._v(" " + _vm._s(_vm.id ? 'Editar' : 'Adicionar') + " Paciente ")], 1)]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "button-right ml-1 botao-white-outline",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.onSubmit();
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "SaveIcon"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Salvar Paciente")])], 1)], 1)], 1)], 1)], 1), _c('b-card', [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Dados do Paciente")]), _c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('b-form', {
          staticClass: "chat-app-form",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.onSubmit);
            },
            "reset": function reset($event) {
              $event.preventDefault();
              return _vm.resetForm.apply(null, arguments);
            }
          }
        }, [_c('b-row', {
          attrs: {
            "align-v": "start"
          }
        }, [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Primeiro Nome"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Primeiro Nome",
            "vid": "firstName",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('b-form-input', {
                attrs: {
                  "placeholder": "Digite aqui"
                },
                model: {
                  value: _vm.form.physicalPerson.firstName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.physicalPerson, "firstName", $$v);
                  },
                  expression: "form.physicalPerson.firstName"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Último Nome"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Último Nome",
            "vid": "lastName",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('b-form-input', {
                attrs: {
                  "placeholder": "Digite aqui"
                },
                model: {
                  value: _vm.form.physicalPerson.lastName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.physicalPerson, "lastName", $$v);
                  },
                  expression: "form.physicalPerson.lastName"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Gênero"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Gênero",
            "vid": "gender",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors;
              return [_c('v-select', {
                attrs: {
                  "options": _vm.genderOptions,
                  "item-text": "name",
                  "item-value": "id",
                  "label": "name"
                },
                model: {
                  value: _vm.form.gender,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "gender", $$v);
                  },
                  expression: "form.gender"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Idade"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "00",
            "disabled": true
          },
          model: {
            value: _vm.form.age,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "age", $$v);
            },
            expression: "form.age"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Data de nascimento",
            "vid": "birthDate",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var errors = _ref5.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Data de Nascimento"
                }
              }, [_c('flat-pickr', {
                staticClass: "form-control",
                attrs: {
                  "config": _vm.configFlatPickr,
                  "placeholder": "DD-MM-AAAA",
                  "required": true
                },
                on: {
                  "on-change": _vm.changeBirthDate
                },
                model: {
                  value: _vm.form.birthDate,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "birthDate", $$v);
                  },
                  expression: "form.birthDate"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Nacionalidade"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Digite aqui"
          },
          model: {
            value: _vm.form.nationality,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "nationality", $$v);
            },
            expression: "form.nationality"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Estado Civil"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Estado Civil",
            "vid": "marital",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var errors = _ref6.errors;
              return [_c('v-select', {
                attrs: {
                  "options": _vm.maritalStatus,
                  "item-text": "name",
                  "item-value": "id",
                  "label": "name"
                },
                model: {
                  value: _vm.form.maritalStatus,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "maritalStatus", $$v);
                  },
                  expression: "form.maritalStatus"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "CPF",
            "vid": "cpf",
            "rules": "required|cpf"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref7) {
              var errors = _ref7.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "CPF"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "placeholder": "Digite aqui"
                },
                model: {
                  value: _vm.form.physicalPerson.cpf,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.physicalPerson, "cpf", $$v);
                  },
                  expression: "form.physicalPerson.cpf"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "CNS"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Digite aqui"
          },
          model: {
            value: _vm.form.cns,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "cns", $$v);
            },
            expression: "form.cns"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Endereço",
            "vid": "address",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref8) {
              var errors = _ref8.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Endereço"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "placeholder": "Rua, Avenida, número"
                },
                model: {
                  value: _vm.form.physicalPerson.person.address,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.physicalPerson.person, "address", $$v);
                  },
                  expression: "form.physicalPerson.person.address"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "CEP",
            "vid": "postalCode",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref9) {
              var errors = _ref9.errors;
              return [_c('b-form-group', [_c('b-form-input', {
                attrs: {
                  "placeholder": "CEP"
                },
                model: {
                  value: _vm.form.physicalPerson.person.postalCode,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.physicalPerson.person, "postalCode", $$v);
                  },
                  expression: "form.physicalPerson.person.postalCode"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Bairro",
            "vid": "neighborhood",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref10) {
              var errors = _ref10.errors;
              return [_c('b-form-group', [_c('b-form-input', {
                attrs: {
                  "placeholder": "Bairro"
                },
                model: {
                  value: _vm.form.physicalPerson.person.neighborhood,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.physicalPerson.person, "neighborhood", $$v);
                  },
                  expression: "form.physicalPerson.person.neighborhood"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Cidade",
            "vid": "city",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref11) {
              var errors = _ref11.errors;
              return [_c('b-form-group', [_c('b-form-input', {
                attrs: {
                  "placeholder": "Cidade"
                },
                model: {
                  value: _vm.form.physicalPerson.person.city,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.physicalPerson.person, "city", $$v);
                  },
                  expression: "form.physicalPerson.person.city"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Estado",
            "vid": "state",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref12) {
              var errors = _ref12.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Estado"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "placeholder": "Digite aqui"
                },
                model: {
                  value: _vm.form.physicalPerson.person.state,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.physicalPerson.person, "state", $$v);
                  },
                  expression: "form.physicalPerson.person.state"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Escolaridade",
            "vid": "schooling",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref13) {
              var errors = _ref13.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Escolaridade"
                }
              }, [_c('v-select', {
                attrs: {
                  "options": _vm.schoolingOptions,
                  "item-text": "name",
                  "item-value": "id",
                  "label": "name"
                },
                model: {
                  value: _vm.form.schooling,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "schooling", $$v);
                  },
                  expression: "form.schooling"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Ocupação"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Digite aqui"
          },
          model: {
            value: _vm.form.occupation,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "occupation", $$v);
            },
            expression: "form.occupation"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "8"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Dependentes"
          }
        }, [_c('b-form-checkbox', {
          staticClass: "mb-1",
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.visitedPsy,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "visitedPsy", $$v);
            },
            expression: "form.visitedPsy"
          }
        }, [_vm._v(" Possui Dependentes? ")]), _c('br'), _c('label', {
          attrs: {
            "for": "tags-basic"
          }
        }, [_vm._v("Preencha conforme o modelo")]), _c('b-form-tags', {
          staticClass: "mb-2",
          attrs: {
            "input-id": "tags-basic",
            "placeholder": "Nome, gênero, idade",
            "add-button-text": "Adicionar"
          },
          model: {
            value: _vm.form.dependents,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "dependents", $$v);
            },
            expression: "form.dependents"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "5"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Telefone"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Fixo"
          },
          model: {
            value: _vm.form.physicalPerson.person.phoneNumber,
            callback: function callback($$v) {
              _vm.$set(_vm.form.physicalPerson.person, "phoneNumber", $$v);
            },
            expression: "form.physicalPerson.person.phoneNumber"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "5"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Celular"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Celular"
          },
          model: {
            value: _vm.form.physicalPerson.person.mobilePhone,
            callback: function callback($$v) {
              _vm.$set(_vm.form.physicalPerson.person, "mobilePhone", $$v);
            },
            expression: "form.physicalPerson.person.mobilePhone"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Email"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "email",
            "placeholder": "exemplo@exemplo.com"
          },
          model: {
            value: _vm.form.physicalPerson.person.email,
            callback: function callback($$v) {
              _vm.$set(_vm.form.physicalPerson.person, "email", $$v);
            },
            expression: "form.physicalPerson.person.email"
          }
        })], 1)], 1)], 1)], 1)];
      }
    }])
  })], 1), _c('b-card', [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Particularidades do Paciente")]), _c('b-form', {
    staticClass: "chat-app-form"
  }, [_c('b-row', {
    attrs: {
      "align-v": "start"
    }
  }, [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.form.visitedPsy,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "visitedPsy", $$v);
      },
      expression: "form.visitedPsy"
    }
  }, [_vm._v(" Visitou um Psicólogo antes? ")])], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.form.outpatient,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "outpatient", $$v);
      },
      expression: "form.outpatient"
    }
  }, [_vm._v(" Ambulatório ")])], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.form.internment,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "internment", $$v);
      },
      expression: "form.internment"
    }
  }, [_vm._v(" Internamento ")])], 1)], 1), _c('b-row', {
    attrs: {
      "align-v": "end"
    }
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('label', {
    attrs: {
      "for": "textarea-default"
    }
  }, [_vm._v("Observações")]), _c('b-form-textarea', {
    attrs: {
      "id": "textarea-default",
      "placeholder": "Informe as observações",
      "rows": "3"
    },
    model: {
      value: _vm.form.note,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "note", $$v);
      },
      expression: "form.note"
    }
  })], 1)], 1)], 1)], 1), _c('b-card', [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Dados da Mãe")]), _c('b-form', {
    staticClass: "chat-app-form"
  }, [_c('b-row', {
    attrs: {
      "align-v": "end"
    }
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Primeiro Nome"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Digite aqui"
    },
    model: {
      value: _vm.form.parents[0].firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.form.parents[0], "firstName", $$v);
      },
      expression: "form.parents[0].firstName"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Último Nome"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Digite aqui"
    },
    model: {
      value: _vm.form.parents[0].lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.form.parents[0], "lastName", $$v);
      },
      expression: "form.parents[0].lastName"
    }
  })], 1)], 1)], 1), _c('b-row', {
    attrs: {
      "align-v": "end"
    }
  }, [_c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Idade"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "placeholder": "00"
    },
    model: {
      value: _vm.form.parents[0].age,
      callback: function callback($$v) {
        _vm.$set(_vm.form.parents[0], "age", $$v);
      },
      expression: "form.parents[0].age"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nacionalidade"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Digite aqui"
    },
    model: {
      value: _vm.form.parents[0].nationality,
      callback: function callback($$v) {
        _vm.$set(_vm.form.parents[0], "nationality", $$v);
      },
      expression: "form.parents[0].nationality"
    }
  })], 1)], 1)], 1), _c('b-row', {
    attrs: {
      "align-v": "end"
    }
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Escolaridade"
    }
  }, [_c('v-select', {
    attrs: {
      "options": _vm.schoolingOptions,
      "item-text": "name",
      "item-value": "id",
      "label": "name"
    },
    model: {
      value: _vm.form.parents[0].schooling,
      callback: function callback($$v) {
        _vm.$set(_vm.form.parents[0], "schooling", $$v);
      },
      expression: "form.parents[0].schooling"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Ocupação"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Digite aqui"
    },
    model: {
      value: _vm.form.parents[0].occupation,
      callback: function callback($$v) {
        _vm.$set(_vm.form.parents[0], "occupation", $$v);
      },
      expression: "form.parents[0].occupation"
    }
  })], 1)], 1)], 1)], 1), _c('h4', {
    staticClass: "mb-2 mt-3"
  }, [_vm._v("Dados do Pai")]), _c('b-form', {
    staticClass: "chat-app-form",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_c('b-row', {
    attrs: {
      "align-v": "end"
    }
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Primeiro Nome"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Digite aqui"
    },
    model: {
      value: _vm.form.parents[1].firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.form.parents[1], "firstName", $$v);
      },
      expression: "form.parents[1].firstName"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Último Nome"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Digite aqui"
    },
    model: {
      value: _vm.form.parents[1].lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.form.parents[1], "lastName", $$v);
      },
      expression: "form.parents[1].lastName"
    }
  })], 1)], 1)], 1), _c('b-row', {
    attrs: {
      "align-v": "end"
    }
  }, [_c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Idade"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "placeholder": "00"
    },
    model: {
      value: _vm.form.parents[1].age,
      callback: function callback($$v) {
        _vm.$set(_vm.form.parents[1], "age", $$v);
      },
      expression: "form.parents[1].age"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nacionalidade"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Digite aqui"
    },
    model: {
      value: _vm.form.parents[1].nationality,
      callback: function callback($$v) {
        _vm.$set(_vm.form.parents[1], "nationality", $$v);
      },
      expression: "form.parents[1].nationality"
    }
  })], 1)], 1)], 1), _c('b-row', {
    attrs: {
      "align-v": "end"
    }
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Escolaridade"
    }
  }, [_c('v-select', {
    attrs: {
      "options": _vm.schoolingOptions,
      "label": "name"
    },
    model: {
      value: _vm.form.parents[1].schooling,
      callback: function callback($$v) {
        _vm.$set(_vm.form.parents[1], "schooling", $$v);
      },
      expression: "form.parents[1].schooling"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Ocupação"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Digite aqui"
    },
    model: {
      value: _vm.form.parents[1].occupation,
      callback: function callback($$v) {
        _vm.$set(_vm.form.parents[1], "occupation", $$v);
      },
      expression: "form.parents[1].occupation"
    }
  })], 1)], 1)], 1), _c('b-row', {
    attrs: {
      "align-v": "end"
    }
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('label', {
    attrs: {
      "for": "textarea-default"
    }
  }, [_vm._v("Observações")]), _c('b-form-textarea', {
    attrs: {
      "id": "textarea-default",
      "placeholder": "Informe as observações",
      "rows": "3"
    },
    model: {
      value: _vm.form.parentNote,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "parentNote", $$v);
      },
      expression: "form.parentNote"
    }
  })], 1)], 1)], 1), _c('b-row', {
    staticClass: "m-1",
    attrs: {
      "align-h": "end"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }