var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "compose-mail",
      "visible": _vm.showModal,
      "title": "Compose Email",
      "footer-class": "d-flex justify-content-between",
      "body-class": "p-0",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }